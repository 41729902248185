import singleSpaReact from 'single-spa-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { type RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { getIsInGenStudio } from './shellHelpers';

export const GenStudioRecordTypeAliases = new Set(['campaigns', 'products', 'personas']);

const getMaestroRoutes = (): RouteObject[] => {
  const routeElements: RouteObject[] = [];

  if (getIsInGenStudio()) {
    GenStudioRecordTypeAliases.forEach((recordTypeAlias) => {
      routeElements.push(
        {
          path: `/${recordTypeAlias}/:workspaceId/:recordTypeId/:viewId/view`,
          lazy: async () => {
            const { viewRecordTypeRouteProperties } = await System.import('@wf-mfe-maestro/view');
            return viewRecordTypeRouteProperties;
          },
        },
        {
          path: `/${recordTypeAlias}/:workspaceId/:recordTypeId/:recordId/record`,
          lazy: async () => {
            const { recordDetailsRouteProperties } = await System.import(
              '@wf-mfe-maestro/details-page-ui'
            );
            return recordDetailsRouteProperties;
          },
        },
        {
          path: `/${recordTypeAlias}`,
          lazy: async () => {
            const { aliasedRecordTypeRouteProperties } = await System.import(
              '@wf-mfe-maestro/view'
            );
            return {
              ...aliasedRecordTypeRouteProperties,
            };
          },
        }
      );
    });
    routeElements.push({
      // Without this, we are getting 404 in GenStudio when
      //   navigating from /insights/campaigns to /products
      // or when
      //   navigating from /content to /personas
      // It looks like react router stays active for some time while
      // single-spa is unmounting the old one and mounting a new app.
      path: '/*',
    });
  }

  return routeElements;
};

export const { bootstrap, mount, unmount } = singleSpaReact({
  React,
  ReactDOM,
  loadRootComponent: async () => {
    // create router in loadRootComponent to make sure to create it only when needed and only once
    const maestroStandaloneRouter = createBrowserRouter(getMaestroRoutes());
    return () => <RouterProvider router={maestroStandaloneRouter} />;
  },
  renderType: 'render',
  suppressComponentDidCatchWarning: true,
  domElementGetter: () => document.getElementById('page-content')!,
});
