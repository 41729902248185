import { getMaestroPath } from './getMaestroPath';
import { getIsInGenStudio } from './shellHelpers';
import { GenStudioRecordTypeAliases } from './singleSpaApplication';

export const getRecordPagePath = (workspaceId: string, recordTypeId: string, recordId: string) => {
  if (getIsInGenStudio()) {
    const parts = window.location.pathname.split('/').filter(Boolean);
    const recordTypeAlias = parts[0];
    if (GenStudioRecordTypeAliases.has(recordTypeAlias)) {
      return `/${recordTypeAlias}/${workspaceId}/${recordTypeId}/${recordId}/record`;
    }
  }
  return getMaestroPath(`/${workspaceId}/${recordTypeId}/${recordId}/record`);
};
